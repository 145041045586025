#create_assignment_page_content_container {
  overflow-y: auto;
}

#create_assignment_step_one,
#create_assignment_step_two,
#create_assignment_step_four {
  max-height: 100%;
  flex-flow: column;
}
.create_assignment_step_content {
  .alert {
    text-align: center;

    width: fit-content;

    justify-self: flex-end;

    height: fit-content;
  }

  .filter_container {
    display: grid;

    grid-template-columns: 200px 150px 330px;

    grid-template-rows: 1fr 1fr;

    grid-template-areas:
      "filter_description filter_description filter_description"
      "filter_lesson_source filter_course filter_unit_filter";

    grid-column-gap: 1%;

    height: 100px;
  }

  .filter_description {
    grid-area: filter_description;

    grid-column: 1/4;

    grid-row: 1/2;
  }

  .filter_lesson_source {
    grid-area: filter_lesson_source;

    grid-column: 1/2;

    grid-row: 2/2;
  }

  .filter_course {
    grid-area: filter_course;

    grid-column: 2/3;

    grid-row: 2/2;

    height: 40px;

    background-color: white;
  }

  .filter_unit_filter {
    grid-area: filter_unit_filter;

    grid-column: 3/4;

    grid-row: 2/2;
  }

  .two_col_grid,
  .create_assignment_step_desc_container {
    display: grid;

    grid-template-columns: 60% 38%;

    grid-gap: 2%;
  }

  .buttons_right_of_filters {
    display: grid;

    grid-template-columns: 1fr 1fr;

    grid-template-rows: 1fr 1fr;

    grid-template-areas:
      ". ."
      "first_button second_button";

    grid-gap: 10px;

    margin-bottom: 10px;

    button {
      height: auto;

      padding: 7px;
    }

    .first_button {
      grid-area: first_button;
    }

    .second_button {
      grid-area: second_button;
    }
  }

  @media (max-width: 1180px) {
    .filter_container {
      grid-template-columns: 1fr;

      grid-template-rows: auto auto auto auto;

      grid-template-areas:
        "filter_description"
        "filter_lesson_source"
        "filter_course"
        "filter_unit_filter";
    }

    .buttons_right_of_filters {
      grid-template-columns: 1fr;

      grid-template-rows: auto auto;

      grid-template-areas:
        "first_button"
        "second_button";

      margin-top: 10px;
    }
  }
  @media (max-width: 780px) {
    .filter_container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto;
      grid-template-areas:
        "filter_description"
        "filter_lesson_source"
        "filter_course"
        "filter_unit_filter";
    }

    .two_col_grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-gap: 10px;
    }

    .buttons_right_of_filters {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      gap: 15px;
    }

    .buttons_right_of_filters .first_button,
    .buttons_right_of_filters .second_button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 40px;
      padding: 0; /* Ensure padding doesn't interfere with alignment */
      box-sizing: border-box; /* Maintain consistency with padding and border */
      margin: 0; /* Eliminate any external spacing issues */
    }
  }

  #unit_selection_flexbox {
    display: flex;

    width: 100%;

    flex-flow: row;

    flex-wrap: wrap;

    grid-gap: 5px;

    margin: 5px 0px 5px 0px;
  }

  .unit_selection {
    background-color: #c4c4c4;

    padding: 5px 5px 5px 10px;

    border-radius: 5px;

    width: fit-content;

    .unit_selection_cancel_btn {
      background-color: transparent;

      border: none;

      cursor: pointer;
    }
  }

  .create_assignment_dropdown_input {
    min-width: 200px;
  }

  .lesson_filter_selection {
    padding: 7px;

    background-color: #e2e2e2;

    color: #4b4b4b;

    border-radius: 5px;
  }
}

.create_assignment_table_container {
  width: 100%;
  overflow-x: auto;
}

.choose_lesson_table,
.choose_learner_table {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  min-width: 590px;

  %choose_lesson_table_grid {
    display: grid;
    grid-gap: 2px; //12 px total
    grid-template-columns:
      40px 100px calc((100% - 304px) / 4) calc((100% - 304px) / 4)
      calc((100% - 304px) / 4) calc((100% - 304px) / 4) 75px 75px;
    text-align: left;

    p {
      margin: 5px;
      hyphens: auto;
      word-wrap: break-word;
    }
  }

  %choose_learner_table_grid {
    display: grid;
    grid-gap: 2px; //10 px total
    grid-template-columns:
      40px calc((100% - 140px) / 4) calc((100% - 140px) / 4)
      calc((100% - 140px) / 4) calc((100% - 140px) / 4) 90px;
    text-align: left;
    align-items: center;

    p {
      margin: 5px;
      hyphens: auto;
      word-wrap: break-word;
      height: fit-content;
    }
  }

  .choose_lesson_table_rows_container,
  .choose_learner_table_rows_container {
    overflow-y: auto;
  }

  .choose_lesson_table_header {
    @extend %choose_lesson_table_grid;
    font-weight: bold;
  }

  .choose_learner_table_header {
    @extend %choose_learner_table_grid;
    font-weight: bold;

    .lessons_completed_learner_data {
      display: grid;
      grid-template-columns: auto max-content;
      p {
        margin: 5px 0px 5px 0px;
      }
    }
  }

  .choose_lesson_table_option_row,
  .choose_learner_table_option_row {
    align-items: center;

    p {
      margin: 0;
    }
  }

  .choose_lesson_table_option_row {
    @extend %choose_lesson_table_grid;
    background-color: #f2f2f2;
    border-top: 1px solid #dfe2e6;
    border-bottom: 1px solid #dfe2e6;
    cursor: pointer;
  }

  .choose_learner_table_option_row {
    @extend %choose_learner_table_grid;
    background-color: #f2f2f2;
    border-top: 1px solid #dfe2e6;
    border-bottom: 1px solid #dfe2e6;
    cursor: pointer;
  }

  .selected_tut {
    background-color: #d6f0ff;
    border-top: 1px solid #40aae3;
    border-bottom: 1px solid #40aae3;
  }

  .asm_row {
    width: calc(100% - 40px);
    margin-left: 40px;
    margin-bottom: 5px;
    border-radius: 0px 0px 10px 10px;
    grid-template-columns:
      0px 100px calc((100% - 265px) / 4) calc((100% - 265px) / 4)
      calc((100% - 265px) / 4) calc((100% - 265px) / 4) 75px 75px;
    background-color: #e2e2e2;
    border: 1px solid #cbced2;

    .index {
      margin-left: -35px;
    }
  }
  .selected_asm {
    background-color: #bde7ff;
    border-top: 1px solid #40aae3;
    border-bottom: 1px solid #40aae3;
  }
}

.create_assignment_table_popup {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no_lessons_to_select {
  padding: 5px;
  text-align: center;
  background-color: #f2f2f2;
}

@media screen and (max-width: 992px) {
  #create_assignment_step_one,
  #create_assignment_step_two {
    max-height: unset;
    .has_content {
      height: fit-content;
      overflow-y: unset;
    }
  }
}

@media screen and (max-height: 610px) {
  #create_assignment_step_one,
  #create_assignment_step_two {
    max-height: unset;
    .has_content {
      height: fit-content;
      overflow-y: unset;
    }
  }
}

@media screen and (max-width: 775px) {
  .create_assignment_step_desc_container {
    grid-template-columns: 100% !important;
  }
}
